import { useState } from "react";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import { Button, message } from "antd";
import URL from "../configs/url";
import IMAGES from "../configs/images";

export default function PayWithCoinbase({ data }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const onFinish = () => {
    setLoading(true);
    try {
      fetch(URL.COINBASE, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res) {
            window.open(res, "_self");
          }
        })
        .catch((err) => {
          message.error(err);
          setLoading(false);
        });
    } catch (err) {}
  };

  return (
    <Button loading={loading} className="pay-btn" onClick={onFinish}>
      <Image src={IMAGES.BITCOIN} /> <span>{t("pay_with_coinbase")}</span>
    </Button>
  );
}
