import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import Image from "next/image";
import IMAGES from "../../configs/images";
import UrlPlaylist from "./UrlPlaylist";

import style from "./playlist.module.scss";

export default function PlaylistActivationPage() {
  const router = useRouter();
  const { t } = useTranslation();

  const [activTabKey, setActivTabKey] = useState("xtream_account");

  const device_key = router?.query?.key;

  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    let timeout = setTimeout(() => {
      const _logoUrl =
        router?.query?.from === "pro" ? IMAGES.PRO_LOGO : IMAGES.LOGO;
      setLogoUrl(_logoUrl);
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [router?.query?.from]);

  const onChangeTabs = (key) => {
    setActivTabKey(key);
  };

  const items = [
    {
      key: "xtream_account",
      label: "Xtream account",
      children: <UrlPlaylist activTabKey={activTabKey} deviceKey={device_key} />
    },
    {
      key: "url",
      label: "URL",
      children: <UrlPlaylist activTabKey={activTabKey} deviceKey={device_key} />
    }
  ];

  return (
    <section className={style["page"]}>
      <div className={style["container"]}>
        <div className={style["logo"]}>
          {logoUrl && <Image src={logoUrl} alt="logo" width={67} />}
        </div>
        <div className={style["form-wrap"]}>
          <div className={style["wrap-info"]}>
            <h1>{t("enjoy_7_days_of_free_trial")}</h1>
            <p className={style["sub-title"]}>
              {t("welcome_to_our_service_enjoy_7_days")}
            </p>

            <h2 className={style["title"]}>
              <span>{t("device_key")}</span> {device_key}
            </h2>
          </div>

          <div className="tabs-playlist-activation">
            <Tabs
              defaultActiveKey={activTabKey}
              items={items}
              onChange={onChangeTabs}
              centered
            />
          </div>
        </div>
      </div>
    </section>
  );
}
