import { useRouter } from 'next/router';

import { Provider } from 'react-redux';
import store from '../redux/store'

import { I18nextProvider } from 'react-i18next';
import i18n from '../i18n/i18n';

import { useState, useEffect } from "react";

import Layout from "../components/Layout";
import ErrorBoundary from "../components/ErrorBoundary";

import favicon from "../public/images/favicon.png";

import Head from "next/head";

import "../styles/globals.scss";

let timeout = null;


export default function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const isoCode = router.locale || 'en';

  const [activeLink, setActiveLink] = useState("home");

  const onChangeActive = (name) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      setActiveLink(name);
    }, 500);
  };

  useEffect(() => {
    if (isoCode) {
      i18n.changeLanguage(isoCode);
    }
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Head>
          <link rel="shortcut icon" href={favicon.src} />
        </Head>
        <ErrorBoundary>
          <Layout activeLink={activeLink} onChangeActive={onChangeActive}>
            <Component {...pageProps} onChangeActive={onChangeActive} />
          </Layout>
        </ErrorBoundary>
      </I18nextProvider>
    </Provider>
  );
}
