import React, { useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import IMAGES from "../../configs/images";
import {
  PACKAGE_PRICE,
  AMERIA_PRODUCT_ID,
  PACKAGE_PRICE_SALE,
  PACKAGE_PRICE_BR,
} from "../../configs/config";
import BuyActivationPackage from "../../components/BuyActivationPackage";
import PayWithCard from "../../components/PayWithCard";
import PayWithCoinbase from "../../components/PayWithCoinbase";

import style from "./buy.module.scss";

export default function BuyActivation() {
  const router = useRouter();
  const { t } = useTranslation();

  const device_key = router?.query?.key;

  const package_data = {
    price: 12,
    old_price: 12,
    br_price: 64,
    old_br_price: 63,
    sale: PACKAGE_PRICE_SALE,
    name: "Life Time",
  };

  const ameria = {
    product_id: AMERIA_PRODUCT_ID,
    price: package_data.price,
    client_id: device_key,
    custom_data: `{"payer": "device", "price": ${package_data.price}}`,
  };

  const coinbase = {
    device_key: device_key,
    price: package_data.price,
  };

  return (
    <section className={style["page"]}>
      <div className={style["container"]}>
        <div className={style["logo"]}>
          <Image src={IMAGES.LOGO} alt="logo" width={67} />
        </div>

        <div className={style["wrap"]}>
          <h1 className={style["title"]}>{t("purchase")}</h1>

          <p className={style["sub-title"]}>{t("by_activating_you_will")}</p>
          <p className={style["green-title"]}>
            <span className={style["green-txt"]}>{t("attention")}!</span>{" "}
            {t("the_app_is_only")}
          </p>
          <h3 className={style["device-key"]}>
            <span>{t("device_key")}</span> {device_key}
          </h3>

          <BuyActivationPackage data={package_data} />
          <PayWithCard data={ameria} />
          <PayWithCoinbase data={coinbase} />
          <p className={style["footer"]}>
            {t("by_clicking_on_button")}{" "}
            <Link href="/privacy-policy">{t("privacy_policy")}</Link>
          </p>
        </div>
      </div>
    </section>
  );
}
