import { useState } from "react";
import { Button, message } from "antd";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import IMAGES from "../configs/images";
import { AMERIA_PAYMENT } from "../configs/config";

export default function PayWithCard({ data }) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onFinish = () => {
    setLoading(true);
    try {
      fetch(AMERIA_PAYMENT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res) {
            window.open(res, "_self");
          }
        })
        .catch((err) => {
          setLoading(false);
          message.error(err);
        });
    } catch (err) {}
  };
  return (
    <Button loading={loading} className="pay-btn" onClick={onFinish}>
      <Image src={IMAGES.PAY_CARD} /> <span>{t("pay_with_card")}</span>
    </Button>
  );
}
